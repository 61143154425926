<template>
  <div class="userWorks">
    <div class="title" v-if="workInfo.state !== 0">
      透析专项规范化操作技能竞赛-{{ workInfo.cszb | cszbFilter}}：{{ workState }}
    </div>
    <div class="videobox" v-if="workInfo.state !== 0">
      <div class="timeios">上传时间：{{ workInfo.bmTime }}</div>
      <div class="left">
        <video
          v-if="workInfo.path"
          width="100%"
          height="100%"
          controls
          style="object-fit: fill"
          ref="video"
        >
          <source :src="aliyunURL + workInfo.path" type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          您的浏览器不支持Video标签。
        </video>
        <div class="play" v-else></div>
      </div>
      <div class="right">
        <div class="content">{{ workInfo.intro }}</div>
        <div class="timepc">上传时间：{{ workInfo.bmTime }}</div>
      </div>
    </div>
    <!-- <div class="title" v-if="workInfo.state >= 5">得分情况</div> -->
    <!-- <div class="table">
      <div class="tables" v-if="workInfo.state === 5">
        {{ workInfo.username }}，您本次参赛作品的得分为{{ average }}
      </div>
      <p v-else-if="workInfo.state === 1">您的作品正在审核中</p>
      <p v-else-if="workInfo.state === 2">
        {{ "您的作品未通过审核,退回原因如下:" + workInfo.nopass_remark }}
      </p>
      <p v-else-if="workInfo.state === 3 || workInfo.state === 4">
        您的作品通过审核，请等待评委评分
      </p>
      <p v-else-if="workInfo.state === 0">您还没有上传过任何作品</p>
      <p v-else>
        <el-table :data="scoreList" border header-align="center" style="width: 500px">
          <el-table-column prop="title" align="center" width="60">
          </el-table-column>
          <el-table-column prop="first" align="center" :label="'初赛('+firstend+')'">
          </el-table-column>
          <el-table-column prop="second" align="center" label="复赛">
          </el-table-column>
          <el-table-column prop="last" align="center" label="决赛"> 
          </el-table-column>
        </el-table>
      </p>
    </div> -->
    <!-- <div class="secondUrl" v-if="workInfo.state == 6">
      恭喜您通过初赛，可点击以下按钮跳转至复赛页面:
      <br>
      <el-button style="margin:10px 0" @click="secondHandle()" >点击进入复赛答题</el-button>
    </div> -->
  </div>
</template>
<script>
import { getEntryInformation } from "../API/user";
import { getToken } from "../../assets/js/storage";
export default {
  data() {
    return {
      aliyunURL: "https://vod.gyycbj.com/",
      workInfo: {},
      workState: "",
      average: 0,
      scoreList:[
        {
          title:'得分',
          first:'',
          second:'',
          last:'',
        }
      ],
      // 复赛链接
      secondUrl:'',
      // 得分结果(通过/未通过)
      firstend:'',
      secondend:'',
      lastend:''
    };
  },
  created() {
    this.getUserWorkInfo();
  },
  methods: {
    // 跳转至复赛页面
    secondHandle(){
      window.open(this.secondUrl,'_blank')
    },
    // 获取参赛作品信息
    async getUserWorkInfo() {
      const { data: res } = await getEntryInformation({
        phase: localStorage.getItem("phase"),
        user_id: getToken().user_id,
      });
      if (res.code !== "0000") {
        // this.workInfo.state = 0;
        return this.$message(res.msg);
      }
      this.workInfo = res.data;
      if (this.workInfo.state == 1) {
        this.workState = "(审核中)";
      } else if (this.workInfo.state == 2) {
        this.workState = "(审核未通过)";
      } else if (this.workInfo.state == 3) {
        this.workState = "(审核通过)";
      }
      if (this.workInfo.score_data) {
        this.average = (this.workInfo.score_data[0].totle +this.workInfo.score_data[1].totle) /2;
        this.scoreList[0].first = this.average //初赛成绩
      }
      if (this.workInfo.state == 6) {
        this.firstend = '通过'
      }else if(this.workInfo.state == 7){
        this.firstend = '未通过'
      }else if(this.workInfo.state == 8){
        this.secondend = '通过'
      }else if (this.workInfo.state == 9) {
        this.secondend = '未通过'
      }
      // 根据参赛组别判断复赛展示链接
      if (this.workInfo.cszb == 1) {
        this.secondUrl = 'https://ks.wjx.top/vm/rp5PWzW.aspx#'
      }else{
        this.secondUrl = 'https://ks.wjx.top/vm/YaaJaEB.aspx#'
      }
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0 || rowIndex === 2) {
          return [2, 1];
        } else {
          return [0, 0];
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.userWorks {
  padding: 74px 40px;
  .secondUrl{
    padding-top: 15px;
    @media screen and (max-width: 540px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 540px) {
    padding: 0;
  }
  .title {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    @media screen and (max-width: 540px) {
      font-size: 16px;
    }
  }
  .videobox {
    border: 1px solid #e2e2e2;
    margin: 28px 0 50px 0;
    height: 210px;
    display: flex;
    @media screen and (max-width: 540px) {
      display: block;
      height: 100%;
    }
    .timeios {
      display: none;
      @media screen and (max-width: 540px) {
        padding: 13px 0 13px 15px;
        display: block;
        color: #666;
        font-size: 12px;
      }
    }
    .left {
      width: 380px;
      background-color: #5b2d9b38;
      height: 100%;
      position: relative;
      .play {
        position: absolute;
        left: 16px;
        bottom: 10px;
        width: 58px;
        cursor: pointer;
        height: 36px;
        background: url("../../assets/image/play.png") no-repeat;
        background-size: 100% 100%;
      }
      @media screen and (max-width: 540px) {
        width: 100%;
        height: 180px;
      }
    }
    .right {
      flex: 1;
      padding-left: 25px;
      padding-top: 40px;
      padding-right: 20px;
      box-sizing: border-box;
      @media screen and (max-width: 540px) {
        flex: none;
        padding-left: 15px;
        padding-top: 0;
      }
      .content::-webkit-scrollbar {
        display: none;
      }
      .content {
        height: 74%;
        overflow-y: scroll;
        @media screen and (max-width: 540px) {
          padding: 13px 0;
        }
      }
      .timepc {
        color: #333333;
        padding-top: 10px;
        @media screen and (max-width: 540px) {
          display: none;
        }
      }
    }
  }
  .table {
    padding-top: 24px;
    @media screen and (max-width: 540px) {
      padding: 24px 0;
    }
    .allnum {
      color: #2d329b;
    }
  }
}
</style>
